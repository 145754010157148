import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ page, limit, keyword }) => {
        let url = `user/allUsers`;
        let pages = page + 1;
        if (pages && limit) {
          url = `/user/allUsers?page=${pages}&limit=${limit}`;
        }
        if (pages && limit && keyword) {
          url = `/user/allUsers?page=${pages}&limit=${limit}&keyword=${keyword}`;
        }
        return url;
      },
      transformResponse: (res) => res,
      providesTags: ['Users'],
    }),

    uploadFile: builder.mutation({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append('files', file);
        return {
          url: `upload/s3`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Users'],
    }),

    addNewProfileBitmojis: builder.mutation({
      query: (data) => ({
        url: `auth/profileBitmojis`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),


    editNewProfileBitmojis: builder.mutation({
      query: ({ id, data }) => ({
        url: `auth/profileBitmojis/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),


    blockUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}/block`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: data,
      }),
      invalidatesTags: ['Users'],
    }),


    unblockUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}/unblock`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: data,
      }),
      invalidatesTags: ['Users'],
    }),

    getAllProfileBitmojis: builder.query({
      query: ({ page, limit }) => {
        let url = `auth/profileBitmojis`;
        let pages = page + 1;
        if (pages && limit) {
          url = `auth/profileBitmojis?page=${pages}&limit=${limit}`;
        }
        return url;
      },
      transformResponse: (res) => res,
      providesTags: ['Users'],
    }),

    deleteProfileBitmojis: builder.mutation({
      query: (id) => ({
        url: `auth/profileBitmojis/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),

  }),
});

export const {
  useGetAllUsersQuery,
  useUploadFileMutation,
  useAddNewProfileBitmojisMutation,
  useGetAllProfileBitmojisQuery,
  useDeleteProfileBitmojisMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useEditNewProfileBitmojisMutation,
} = usersApi;
